<template>
  <Transition name="fade">
    <div
      v-if="showing"
      class="fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75 bg-gray-100 bg-opacity-60"
    >
      <div class="w-full max-w-2xl bg-white shadow-lg rounded-lg p-8 relative">
        <button
          aria-label="close"
          class="absolute top-0 right-0 text-xl text-gray-500 my-2 mx-4"
          @click.prevent="close"
        >
          X
        </button>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "modal",
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      
    };
  },
  watch: {
    showing(value) { // Fixing scroll issues
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden');
      }

      document.querySelector('body').classList.remove('overflow-hidden');
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
