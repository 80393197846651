<template>
  <div class="relative pt-1" v-if="uploading != 100">
    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
      <div :style="{width: uploading + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-700"></div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  props: ['uploading'],
  // computed: {
  //   ...mapGetters(['uploading'])
  // },
}
</script>

<style>

</style>